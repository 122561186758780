import { faFileContract, faSignature } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Avatar, Box, Button, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Table, TableBody, TableCell, TableContainer, TableRow, Toolbar, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import * as moment from "moment";
import React, { useEffect } from "react";
import InteractionStatus from "../../../components/interaction-status/InteractionStatus";
import DocumentTimeline from "./DocumentTimeline";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { usePdfViewerInstance } from "../../../components/pdf-viewer/usePdfViewerInstance";
import { validUser } from "../../../app/reducer/userSlice";
import { selectAll } from "../inbox/inboxSlice";

const maxDrawerWidth = 304;

const DrawerContent = ({ document }) => {
   const envelope = useSelector((state) => state.envelope.entity);
   const dispatch = useDispatch();
   const { id: envId } = useParams();
   const envelopes = useSelector(selectAll);
   const documentStatus = envelopes.filter(item => item.id == envId);
   const userSignStatus = ['Signed', "Completed", "Declined", "In Progress"].includes(documentStatus[0]?.status);
   const valid = useSelector(state => state.user.valid);
   const { prevAnnotationField, nextAnnotationField } = usePdfViewerInstance();

   useEffect(() => {
      dispatch(validUser());
   }, [])

   return (
      <Box sx={{ overflow: "auto", p: 1 }}>
         <Box p={1}>
            <Button startIcon={<FontAwesomeIcon icon={faFileContract} />} size="large" sx={{ fontSize: "1rem" }} component={RouterLink} to="">
               Document
            </Button>
         </Box>

         <TableContainer>
            <Table size="small">
               <TableBody>
                  <TableRow>
                     <TableCell>Version</TableCell>
                     <TableCell>1.0</TableCell>
                  </TableRow>
                  <TableRow>
                     <TableCell>Status</TableCell>
                     <TableCell>
                        <InteractionStatus status={document?.status} />
                     </TableCell>
                  </TableRow>
                  <TableRow>
                     <TableCell>Requested On</TableCell>
                     <TableCell>
                        {document?.updatedOn ? moment(document?.updatedOn).format("MMM Do, YYYY") : ""}
                     </TableCell>
                  </TableRow>
                  {document?.status === "Completed" ? (
                     <TableRow>
                        <TableCell>Completed On</TableCell>
                        <TableCell>
                           {envelope?.completedDate ? moment(envelope?.completedDate).format("MMM Do, YYYY")
                              : ""}
                        </TableCell>
                     </TableRow>
                  ) : (
                     <TableRow>
                        <TableCell>Sign Due Date</TableCell>
                        <TableCell>
                           {document?.updatedOn ? moment(document?.updatedOn).add(90, "days").format("MMM Do, YYYY") : ""}
                        </TableCell>
                     </TableRow>
                  )}
               </TableBody>
            </Table>
         </TableContainer>
         {valid ? '' : <> <Box pt={3}>
            <Button size="large" sx={{ fontSize: "1rem" }}>
               Navigation Field
            </Button>
         </Box>
            <Divider />
            <Box display="flex" justifyContent="space-left" p={2}>
               {/* <Button size="small" startIcon={<ArrowBackIos />} color="info" variant="outlined" onClick={prevAnnotationField} > PREVIOUS FIELD </Button> */}
               <Button size="small" startIcon={<ArrowForwardIos />} color="info" variant="outlined" disabled={userSignStatus} onClick={nextAnnotationField}>
                  CLICK HERE TO SIGN
               </Button>
            </Box>
         </>}

         {/* 
            <Typography px={2} pt={3} color="text.secondary">
               FILL & SIGN
            </Typography>
            <List dense sx={{ pt: 1 }}>
               <ListItem
                  disablePadding
                  secondaryAction={
                     <Avatar
                        sx={{ width: 16, height: 16, bgcolor: green[600] }}
                        variant="rounded"
                     >
                        <Typography variant="caption">3</Typography>
                     </Avatar>
                  }
               >
                  <ListItemButton>
                     <ListItemIcon sx={{ minWidth: 24, mr: 1 }}>
                        <FontAwesomeIcon
                           icon={faTextWidth}
                           className={"icon-fixed-width"}
                           color={green[500]}
                        />
                     </ListItemIcon>
                     <ListItemText primary="Form Fields" sx={{ opacity: 1 }} />
                  </ListItemButton>
               </ListItem>
               <ListItem
                  disablePadding
                  secondaryAction={
                     <Avatar
                        sx={{ width: 16, height: 16, bgcolor: green[600] }}
                        variant="rounded"
                     >
                        <Typography variant="caption">2</Typography>
                     </Avatar>
                  }
               >
                  <ListItemButton>
                     <ListItemIcon sx={{ minWidth: 24, mr: 1 }}>
                        <FontAwesomeIcon
                           icon={faSignature}
                           className={"icon-fixed-width"}
                           color={green[500]}
                        />
                     </ListItemIcon>
                     <ListItemText primary="Signatures" sx={{ opacity: 1 }} />
                  </ListItemButton>
               </ListItem>
            </List>

            <Box display="flex" justifyContent="space-around">
               <Button
                  size="small"
                  startIcon={<ArrowBackIos />}
                  color="info"
                  variant="outlined"
               >
                  PREVIOUS
               </Button>
               <Button
                  size="small"
                  startIcon={<ArrowForwardIos />}
                  color="info"
                  variant="outlined"
               >
                  NEXT
               </Button>
            </Box>

            <Divider sx={{ pb: 3 }} /> 
         */}

         {/*<Divider sx={{ pb: 3 }} />*/}

         {/*<Typography px={2} pt={2} color="text.secondary">TIMELINE</Typography>*/}
         {/*<DocumentTimeline document={document} />*/}
      </Box>
   );
};

const DocumentDrawer = ({ document }) => {
   return (
      <Drawer variant="permanent" anchor="left" sx={{ width: maxDrawerWidth, flexShrink: 0, "& .MuiDrawer-paper": { width: maxDrawerWidth, boxSizing: "border-box", left: "inherit" } }}>
         <Toolbar />
         <DrawerContent document={document} />
      </Drawer>
   )
};

export default DocumentDrawer;
