import { faSign, faSignature } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, Divider } from "@mui/material";
import { Box, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Logo from '../../assets/images/Simplify-eSign-Logo.png'
import { useEffect, useRef, useState } from "react";


export const BrandLogo = (props) => {
   const theme = useTheme();
   const [loading, setLoading] = useState(true);
   const clearTime = useRef(null);

   useEffect(() => {
      clearTime.current = setTimeout(() => setLoading(false), 2000);
      return () => clearTimeout(clearTime.current)
   }, [Logo])


   return (
      <Box pl={{ xs: 1, md: 3 }} sx={{ backgroundColor: '#fff' }}> {/*, py: "0.48rem"*/}
         {/* <Box sx={{ textAlign: loading ? 'center' : '' }}> */}
         <CircularProgress size={30} sx={{ display: loading ? '' : 'none', marginLeft: '35%' }} />
         <img src={Logo} alt="Logo" style={{ width: '170px', display: loading ? 'none' : '', verticalAlign: 'middle' }} />
         {/* </Box> */}
         {/* <Box sx={{ border: 'solid 1px #ccc', padding: '0.5rem', borderRadius: '0.25rem', background: 'aliceblue' }}>
                <FontAwesomeIcon icon={faSignature} size="2x" color={theme.palette.primary.main} />
            </Box>
            <Box>
                <Box sx={{ display: 'flex' }}>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: 20, fontWeight: 500 }}>Simply</Typography>
                    <Typography sx={{ color: theme.palette.secondary.main, fontSize: 20, fontWeight: 300, fontStyle: 'italic', textDecoration: 'underline' }}>Sign</Typography>
                </Box>
                <Typography sx={{ color: theme.palette.text.primary, fontSize: 10 }}>By Simplify Healthcare &trade;</Typography>
            </Box> */}
      </Box>
   );
};