import { Box, CssBaseline } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchEnvelope, selectDocumentById } from './../envelope/envelopeSlice';
import DocumentDrawer from './DocumentDrawer';
import DocumentViewer from './DocumentViewer';

const Document = () => {
   const { id: envId } = useParams();
   const { docId } = useParams();
   const dispatch = useDispatch();
   const document = useSelector(state => selectDocumentById(state, docId));

   useEffect(() => {
      dispatch(fetchEnvelope(envId))
   }, []);

   return (
      <Box sx={{ display: 'flex' }}>
         <CssBaseline />
         <DocumentDrawer document={document} />
         <Box sx={{ flexGrow: 1, bgColor: 'background.default', px: 3, pt: 2 }}>
            <DocumentViewer document={document} />
         </Box>
      </Box >
   );
};
export default Document;
