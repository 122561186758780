import { Box } from "@mui/material";
import WebViewer from '@pdftron/webviewer';
import React, { useContext, useEffect, useRef } from "react";
import PdfViewerContext from "./PdfViewerContext";
import { useMsal } from "@azure/msal-react";
import './PdfViewer.css';
import { useDispatch, useSelector } from "react-redux";
import { selectAll } from "../../features/signature/inbox/inboxSlice";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { recipientStatus } from "../../constants/constants";
import { validUser } from "../../app/reducer/userSlice";

function disabledToolButton(iframeDoc, document) {
   //Disable toolbargroup menuitems
   let toolbarMenuItem = iframeDoc.querySelectorAll('.Dropdown__items button.Dropdown__item');
   let toolbarMenuItem2 = iframeDoc.querySelectorAll('button.ribbon-group');
   let commentsBtn = iframeDoc.querySelectorAll('[data-element="toggleNotesButton"],[data-element="searchButton"],[data-element="selectToolButton"],[data-element="outlinesPanelButton"]');

   if (document) {
      for (let btn of commentsBtn) {
         btn.classList.add('disabled');
         btn.setAttribute('disabled', true);
      }
      iframeDoc.querySelector('[data-element="panToolButton"]').click();
   }

   toolbarMenuFun(toolbarMenuItem);
   toolbarMenuFun(toolbarMenuItem2);

   function toolbarMenuFun(item) {
      let pickedText = iframeDoc.querySelector('.picked-option-text')
      for (let i = 0; i < item.length; i++) {
         if (i < 5 || i == 6) {
            item[i].removeAttribute('data-element');
            item[i].setAttribute('disabled', true);
            item[i].setAttribute('style', `cursor: not-allowed; opacity: 0.5;`)
            item[i].classList.remove('active')
         }
         if (document) {
            item[i].removeAttribute('data-element');
            item[i].setAttribute('disabled', true);
            item[i].setAttribute('style', `cursor: not-allowed; opacity: 0.5;`)
         }
         if (i == 5) {
            item[i].classList.add('active');
            pickedText.innerHTML = item[i].innerHTML
         }
      }
   }

}

const PdfViewer = ({ setDisableSubmit, secureWidgetAnnotations = true }) => {
   const { id: envId } = useParams();
   const dispatch = useDispatch();
   const valid = useSelector(state => state.user.valid);
   const envelopes = useSelector(selectAll);
   const documentStatus = recipientStatus(envelopes, envId);
   const [signedDocument, setSignedDocument] = useState(null);
   const activeAccount = useMsal().instance.getActiveAccount();
   const activeUserName = activeAccount.username.toLowerCase();
   const { setInstance, setAnnotManager, instance } = useContext(PdfViewerContext);
   const viewer = useRef(null);
   const docViewerLoad = useRef(false);

   useEffect(() => {
      setSignedDocument(documentStatus || valid);
   }, [envelopes, valid]);

   useEffect(() => {
      dispatch(validUser());
      if (!docViewerLoad.current) {
         docViewerLoad.current = true;
         WebViewer(
            {
               licenseKey: 'The Most Group Inc  dba Simplify Healthcare Technology:OEM:CollaborateTM::B+:AMS(20251019):B3A5A46204C7380A8360B03AC9A2527860611F859F78FD92DD9F7BFACF525037128A31F5C7',
               fullAPI: true,
               path: '/webviewer',
               annotationUser: activeAccount.localAccountId,
            },
            viewer.current,
         ).then(async instance => {
            const { annotationManager, documentViewer } = instance.Core;
            const { UI, Feature, annotManager, Annotations } = instance;
            const iframeDoc = UI.iframeWindow.document;
            const signatureTool = documentViewer.getTool('AnnotationCreateSignature');

            const defaultMouseLeftUp = signatureTool.mouseLeftUp;
            signatureTool.mouseLeftUp = function (e, widget) {
               // ignore "mouseLeftUp" event when no widget is selected
               if (!widget) return;
               defaultMouseLeftUp.apply(this, arguments);
            }

            // Default web viewer settings
            UI.setFitMode(instance.FitMode.FitWidth);
            UI.enableFeatures([UI.Feature.Initials]);

            // Show loader
            UI.openElements(['loadingModal']);
            UI.disableFeatures([Feature.ThumbnailMerging, Feature.ThumbnailReordering]);
            UI.disableElements(['rubberStampToolGroupButton', 'toggleCompareModeButton', 'signaturePanel', 'signaturePanelButton']);

            // Set authors map
            annotationManager.setAnnotationDisplayAuthorMap((userId) => {
               // TODO: Add switch-case with id-name for all recipients on given envelope
               return activeAccount.name;
            })

            const createSignHereElement = Annotations.SignatureWidgetAnnotation.prototype.createSignHereElement;

            Annotations.SignatureWidgetAnnotation.prototype.createSignHereElement = function () {
               const signHereElement = createSignHereElement.apply(this, arguments);
               this.innerElement.style.height = '23px';
               return signHereElement;
            }

            // Register all event listeners
            documentViewer.addEventListener('documentLoaded', () => {
               UI.closeElements(['loadingModal']);
            });

            function getSplitEmaild(annot) {
               const isSecureField = annot.fieldName.split('_').filter(item => item.match(/[@]/g)).filter(item => item.toLowerCase() === activeUserName)[0];
               return isSecureField;
            }

            function getCurrentUser(annot) {
               const isSecureField = getSplitEmaild(annot);
               const fieldRecipient = isSecureField === activeUserName;
               if (fieldRecipient) return fieldRecipient;
            }

            function normalStyles(widget) {
               if (widget instanceof Annotations.SignatureWidgetAnnotation) {
                  if (getSplitEmaild(widget) === activeUserName) {
                     return {
                        border: '1px solid #a5c7ff',
                     }
                  }
               }
            };

            annotManager.on('annotationChanged', (annotations, action, { imported }) => {
               const signatureWidgetAnnots = annotationManager.getAnnotationsList().filter(annot => annot instanceof Annotations.SignatureWidgetAnnotation);
               const textWidgetAnnots = annotationManager.getAnnotationsList().filter(annot => annot instanceof Annotations.TextWidgetAnnotation);

               if (imported && action === 'add') {
                  annotations.forEach(function (annot) {
                     if (annot instanceof Annotations.WidgetAnnotation) {
                        if (getCurrentUser(annot)) {
                           Annotations.WidgetAnnotation.getCustomStyles = normalStyles;
                           if (textWidgetAnnots && secureWidgetAnnotations) {
                              annot.fieldFlags.set('ReadOnly', false);
                           }
                        }
                        else {
                           if (textWidgetAnnots || secureWidgetAnnotations) {
                              annot.fieldFlags.set('ReadOnly', true);
                           }
                        }
                     }
                  })
               }

               if (action === 'add') {
                  signatureWidgetAnnots.forEach(annot => {
                     if (getCurrentUser(annot)) {
                        if (annot.CA !== null && Object.keys(annot?.CA)?.length > 0) {
                           setDisableSubmit(false);
                        }
                     }
                  })
               }
               else if (action === 'delete') {
                  signatureWidgetAnnots.forEach(annot => {
                     if (getCurrentUser(annot)) {
                        if (!['CA'].includes(Object.keys(annot?.CA))) {
                           setDisableSubmit(true);
                        }
                     }
                  });
               }
            });

            documentViewer.addEventListener('annotationsLoaded', () => {
               const signatureWidgetAnnots = annotationManager.getAnnotationsList().filter(annot => annot instanceof Annotations.SignatureWidgetAnnotation);
               signatureWidgetAnnots.forEach(annot => {
                  annot.isSignedDigitally().then(isSigned => {
                     if (isSigned) {
                        console.log(annot.fieldName, "Is signed");
                     } else {
                        console.log(annot.fieldName, "Not signed");
                     }
                  })
               });
            });

            // Set instance in context to make it available to all context consumers
            setInstance(instance);
            setAnnotManager(annotManager);
         });
      }
   }, []);

   useEffect(() => {
      if (!instance) return;
      if (signedDocument) {
         const { PDFNet, annotationManager, documentViewer } = instance?.Core;
         const { UI, Feature } = instance;
         const iframeDoc = UI.iframeWindow.document;

         //Document Signed features button disabled
         documentViewer.addEventListener('documentLoaded', () => {
            console.log("~Document loaded!!!");
            instance.hotkeys.off();
            instance.disableElements(['contextMenuPopup']);
            UI.disableElements(['toolsHeader', 'thumbnailControl', 'thumbMultiSelect', 'documentControl', 'annotationPopup', 'selectToolButton']);
            UI.disableFeatures([Feature.ThumbnailMultiselect, Feature.PageNavigation]);

            disabledToolButton(iframeDoc, document);

            (async () => {
               await PDFNet?.initialize();
               const doc = await documentViewer?.getDocument()?.getPDFDoc();
               // export annotations from the document
               const annots = await annotationManager?.exportAnnotations();

               // Run PDFNet methods with memory management
               await PDFNet.runWithCleanup(async () => {

                  // lock the document before a write operation
                  // runWithCleanup will auto unlock when complete
                  doc.lock();

                  // import annotations to PDFNet
                  const fdf_doc = await PDFNet?.FDFDoc.createFromXFDF(annots);
                  await doc.fdfUpdate(fdf_doc);

                  // flatten all annotations in the document
                  await doc.flattenAnnotations();

                  // or optionally only flatten forms
                  // await doc.flattenAnnotations(true);

                  // clear the original annotations
                  annotationManager.deleteAnnotations(annotationManager.getAnnotationsList(), { force: true });

                  // optionally only clear widget annotations if forms were only flattened
                  // const widgetAnnots = annots.filter(a => a instanceof Annotations.WidgetAnnotation);
                  // annotationManager.deleteAnnotations(widgetAnnots);
               });

               // clear the cache (rendered) data with the newly updated document
               documentViewer.refreshAll();

               // Update viewer to render with the new document
               documentViewer.updateView();

               // Refresh searchable and selectable text data with the new document
               documentViewer.getDocument().refreshTextData();
            })()
         })

      }
      return () => false
   }, [instance, signedDocument])


   return (
      <Box className='pdf-viewer' mt={2}>
         <Box className="webviewer" ref={viewer} style={{ height: 'calc(100vh - 170px)' }}></Box>
      </Box>
   );
};

export default PdfViewer;