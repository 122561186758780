import { configureStore } from '@reduxjs/toolkit';
import InboxReducer from './../features/signature/inbox/inboxSlice';
import EnvelopeReducer from './../features/signature/envelope/envelopeSlice';
import UserReducer from './reducer/userSlice'

export const store = configureStore({
   reducer: {
      inbox: InboxReducer,
      envelope: EnvelopeReducer,
      user: UserReducer
   },
});
