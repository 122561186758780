import { Chip, LinearProgress, Paper, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { faCheckCircle, faClock, faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataGrid } from '@mui/x-data-grid';
import { CustomNoRowsOverlay } from '../../../components/grid-overlay/GridOverlay';
import { useState } from 'react';

const columns = [
   {
      field: 'name', headerName: 'Recipient', maxWidth: 150, flex: 4, renderCell: (params) => (
         <Typography variant="subtitle" color="text.primary" sx={{ fontWeight: '700' }}>{`${params.row.firstName} ${params.row.lastName}`}</Typography>
      )
   },
   {
      field: 'email', headerName: 'Email Id', maxWidth: 300, flex: 4
   },
   {
      field: 'roleName', headerName: 'Role', maxWidth: 200, flex: 2, renderCell: (params) => (
         <Chip label={params.value} color="info" variant="outlined" sx={{ minWidth: 100, textAlign: 'left' }} />
      )
   },
   {
      field: 'routingOrder', headerName: 'Routing Order', maxWidth: 150, flex: 2, renderCell: (params) => {
         if (params.row.applicableDocuments === 0 || ['editor', 'owner/editor', 'carboncopy'].includes(params.row.roleName.toLowerCase())) {
            return '--';
         }
      }
   },
   {
      field: 'consented', headerName: 'Consents', maxWidth: 200, flex: 3, renderCell: (params) => {
         if (params.row.applicableConsentForms === 0 || ['editor', 'owner/editor', 'carboncopy'].includes(params.row.roleName.toLowerCase())) {
            return '--';
         }

         if (params.row.applicableConsentForms === params.row.consented) {
            return <Chip icon={<FontAwesomeIcon icon={faCheckCircle} size="xl" />} label={`Complete`} color="success" variant="outlined" />
         }

         return (
            <Chip icon={<FontAwesomeIcon icon={faClock} size="xl" />}
               label={`${params.row.applicableConsentForms - params.row.consented} of ${params.row.applicableConsentForms} pending`} color="warning" variant="outlined" />
         )
      }
   },
   {
      field: 'signed', headerName: 'Signatures', maxWidth: 200, flex: 3, renderCell: (params) => {
         if (params.row.applicableDocuments === 0 || ['editor', 'owner/editor', 'carboncopy'].includes(params.row.roleName.toLowerCase())) {
            return '--';
         }

         if (params.row.applicableDocuments === params.row.signed) {
            return <Chip icon={<FontAwesomeIcon icon={faCheckCircle} size="xl" />} label={`Complete`} color="success" variant="outlined" />
         }

         if (params.row.status === "Declined") {
            return <Chip icon={<FontAwesomeIcon icon={faBan} size="xl" />} label={`Declined`} color="error" variant="outlined" />
         }

         return (
            <Chip icon={<FontAwesomeIcon icon={faClock} size="xl" />}
               label={`${params.row.applicableDocuments - params.row.signed} of ${params.row.applicableDocuments} pending`} color="warning" variant="outlined" />
         )
      }
   }
   /* ,{
      field: 'uploaded', headerName: 'Signer Attachments', minWidth: 100, flex: 3, renderCell: (params) => {
         if (params.row.applicableSignerAttachments === 0) {
            return '--';
         }

         if (params.row.Signatures === params.row.signed) {
            return <Chip icon={<FontAwesomeIcon icon={faCheckCircle} size="xl" />} label={`Complete`} color="success" variant="outlined" />
         }

         return (
            <Chip icon={<FontAwesomeIcon icon={faClock} size="xl" />}
               label={`${params.row.applicableSignerAttachments - params.row.uploaded} of ${params.row.applicableSignerAttachments} pending`} color="warning" variant="outlined" />
         )
      }
   } */
];

const RecipientsList = () => {
   const [data, setData] = useState([]);
   const [focusCard, setFocusCard] = useState(null);
   const clearFocusCard = useRef(null);

   const envelope = useSelector((state) => state.envelope.entity);
   const envelopeCard = useSelector(state => state.envelope.cards);

   useEffect(() => {
      if (envelopeCard.payload) {
         clearFocusCard.current = setTimeout(() => {
            setFocusCard(envelopeCard.payload, clearTimeout(clearFocusCard.current))
         }, 500);
      }

   }, [envelopeCard.payload])

   useEffect(() => {
      if (!envelope || envelope.recipients?.length === 0) return;

      const records = envelope.recipients.map(r => {
         const consentsX = envelope.consentForms.map(cf => cf.consents.find(c => c.recipientId === r.id));
         const consents = envelope.consentForms.filter(cf => cf.consents.some(c => c.recipientId === r.id)).map(cf => cf.consents.find(c => c.recipientId === r.id))

         const documentsX = envelope.documents.map(cf => cf.signatures.find(c => c.recipientId === r.id));
         const documents = envelope.documents.filter(cf => cf.signatures.some(c => c.recipientId === r.id)).map(cf => cf.signatures.find(c => c.recipientId === r.id))

         return {
            ...r,
            applicableConsentForms: consents.length,
            consented: consents.filter(c => c.statusName === "Consented").length,
            applicableDocuments: documents.length,
            signed: documents.filter(c => c.statusName === "Signed").length,
            applicableSignerAttachments: envelope.signerAttachments?.length || 0,
            uploaded: envelope.signerAttachments?.filter(sa => sa.fileName)?.length || 0,
            status: documents[0]?.statusName || '-'
         }
      });

      const ownerData = {
         id: envelope.id,
         firstName: envelope.ownerName.split(" ")[0],
         lastName: envelope.ownerName.split(" ")[1],
         email: envelope.ownerEmail,
         roleName: "Owner/Editor"
      };

      setData([...records, ownerData]);
   }, [envelope]);

   return (
      <Paper sx={{ mt: 2, height: 375, width: '100%' }} elevation={focusCard ? 2 : 0}>
         <DataGrid
            autoheight
            components={{
               NoRowsOverlay: CustomNoRowsOverlay,
               LoadingOverlay: LinearProgress
            }}
            rows={data.map((row, index) => ({ ...row, id: index }))}
            columns={columns}
            onRowClick={() => { }}
            sx={{
               borderColor: focusCard ? 'primary.light' : '',
               '& .MuiDataGrid-cell, & .MuiDataGrid-cell *': {
                  cursor: 'default'
               },
               "& .MuiDataGrid-container--top::after": {
                  background: 'none',
                  borderBottom: '1px solid var(--DataGrid-rowBorderColor)'
               },
               "& .MuiDataGrid-container--top [role=row]": {
                  background: '#fff'
               },
               "& .MuiDataGrid-columnSeparator": {
                  visibility: 'visible'
               },
               "& .MuiDataGrid-footerContainer": {
                  border: data.length > 5 && 'none'
               },
               "& .MuiDataGrid-row:last-child .MuiDataGrid-cell": {
                  borderBottom: data.length > 5 && '1px solid var(--rowBorderColor)'
               }
            }}
         />
      </Paper>
   );
}

export default RecipientsList;
