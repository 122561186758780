import { faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { Refresh } from '@mui/icons-material';
import { Box, Button, LinearProgress, Paper, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomNoRowsOverlay } from '../../../components/grid-overlay/GridOverlay';
import InteractionStatus from '../../../components/interaction-status/InteractionStatus';
import { PageTitle } from '../../../components/layout/PageTitle';
import { fetchEnvelopeStatusCount } from '../envelope/envelopeSlice';
import { refreshInbox, selectAll } from './inboxSlice';
import { removeUser } from '../../../app/reducer/userSlice';

const columns = [
   //{
   //    field: 'id', headerName: 'Action', minWidth: 60, flex: 1,
   //    renderCell: (params) => (
   //        <Button variant="text">Open</Button>
   //    )
   //},
   { field: 'id', headerName: 'Id', minWidth: 60, flex: 1 },
   { field: 'ownerName', headerName: 'Owner', minWidth: 150, flex: 4 },
   {
      field: 'title', headerName: 'Title', minWidth: 300, flex: 9,
      renderCell: (params) => (
         <Tooltip title={params.value} placement='bottom-start' PopperProps={{
            sx: {
               '& .MuiTooltip-tooltip': {
                  fontSize: '12px'

               },
               "& .MuiTooltip-arrow": {
                  left: "10px !important",
                  transform: "none !important"
               }
            }, modifiers: [
               {
                  name: 'offset',
                  options: {
                     offset: [-5, -25]
                  }
               }
            ]
         }} arrow disableInteractive>
            <Typography variant="subtitle" color="text.primary" sx={{ fontWeight: '700', textOverflow: 'ellipsis', overflow: 'hidden', height: '100%', display: 'flex', alignItems: "center", cursor: 'pointer' }}><span className='title'>{params.value}</span></Typography>
         </Tooltip>
      )
   },
   { field: 'role', headerName: 'Your Role', minWidth: 80, flex: 3 },
   {
      field: 'status', headerName: 'Status', minWidth: 180, flex: 3,
      renderCell: (params) => {
         return <InteractionStatus status={params.value} />
      }
   },
   {
      field: 'createdOn', headerName: 'Requested Date', minWidth: 120, flex: 3,
      renderCell: (params) => (
         <Typography variant="subtitle" color="text.primary">{moment(params.value).format("MMM DD, YYYY")}</Typography>
      )
   }
];

const InboxTitle = () => {
   const dispatch = useDispatch();

   const handleRefreshInbox = async () => {
      await dispatch(refreshInbox());
   }

   return (
      <Box px={2} pt={2} sx={{ display: 'flex', justifyContent: "space-between" }}>
         <Typography variant="subtitle1" color="primary" sx={{ fontWeight: 'bold' }}>Inbox (last 6 months)</Typography>
         <Button variant="outlined" color="secondary" startIcon={<Refresh />} onClick={handleRefreshInbox}>Refresh Inbox</Button>
      </Box>
   );
}

const Inbox = ({ fullVariant = true }) => {
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const envelopes = useSelector(selectAll);
   const loadingStatus = useSelector((state) => state.inbox.status);
   const loadingError = useSelector((state) => state.inbox.error);

   const [data, setData] = useState([])

   useEffect(() => {
      if ((envelopes && envelopes.length > 0)) return;
      if (loadingStatus !== 'idle') return;
      dispatch(removeUser());
      dispatch(refreshInbox());
   }, [])

   useEffect(() => {
      if (envelopes && envelopes.length > 0) setData(envelopes);
   }, [envelopes])

   const handleRefreshInbox = () => {
      dispatch(refreshInbox());
      dispatch(fetchEnvelopeStatusCount())
   }

   const openEnvelope = (params) => {
      dispatch(removeUser())
      navigate(`envelopes/${params.id}`);
   }

   return (
      <Box>
         {
            fullVariant && (
               <PageTitle title="Inbox" faIcon={faMailBulk} helperText="Below envelopes are routed to you for action. Click on envelope to open."
                  rightAction={<Button variant="outlined" color="secondary" startIcon={<Refresh />} onClick={handleRefreshInbox}>Refresh Inbox</Button>}
               />
            )
         }

         <Paper sx={{ mt: 2, height: 'calc(100vh - 272px)', minHeight: 200, width: '100%' }}>
            <DataGrid
               autoheight
               components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  LoadingOverlay: LinearProgress,
                  Toolbar: InboxTitle
               }}
               loading={loadingStatus === 'pending'}
               rows={data}
               columns={columns}
               onRowClick={openEnvelope}
               error={loadingError}
               sx={{
                  cursor: 'pointer',
                  "& .MuiDataGrid-container--top [role=row]": {
                     background: '#fff'
                  },
                  "& .MuiDataGrid-columnSeparator": {
                     visibility: 'visible'
                  },
                  "& .MuiDataGrid-footerContainer": {
                     border: data.length > 6 && 'none'
                  },
                  "& .MuiDataGrid-cell .title": {
                     display: 'block',
                     textOverflow: 'ellipsis',
                     whiteSpace: 'nowrap',
                     overflow: 'hidden',
                  },
                  "& .MuiDataGrid-columnSeparator--sideRight": {
                     right: '-9px'
                  },
                  "& .MuiDataGrid-row:last-child .MuiDataGrid-cell": {
                     borderBottom: data.length > 7 && '1px solid var(--rowBorderColor)'
                  }
               }}
            />
         </Paper>
      </Box>
   );
};
export default Inbox;
