export const REQUEST_STATUS = {
   SIGNED: "Signed",
   COMPLETED: "Completed",
   IN_PROGRESS: "In Progress",
   INPROGRESS: "InProgress",
   DECLINED: "Declined",
   READY: "Ready"
}

export const recipientStatus = (envelopes, id) => {
   const status = [REQUEST_STATUS.SIGNED, REQUEST_STATUS.COMPLETED, REQUEST_STATUS.DECLINED, REQUEST_STATUS.IN_PROGRESS].includes(envelopes?.filter(item => item.id == id)[0]?.status);
   return status
} 