import { AuthenticatedTemplate } from '@azure/msal-react';
import React, { useEffect } from 'react';
import Dashboard from '../../features/dashboard/Dashboard';
import { useDispatch } from 'react-redux'
import { recipientCard } from '../../features/signature/envelope/envelopeSlice';
import './../../assets/styles/style.css';

const LandingPage = () => {
   const dispatch = useDispatch();
   useEffect(() => {
      dispatch(recipientCard({ payload: false }))
      return () => false
   }, [])

   return (
      <AuthenticatedTemplate>
         <Dashboard />
      </AuthenticatedTemplate>
   );
};
export default LandingPage;
