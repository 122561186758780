import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { msalInstance } from './../index';

export const getToken = async (scopes) => {
   const account = msalInstance.getActiveAccount();

   if (!account) {
      throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
   }

   const response = await msalInstance.acquireTokenSilent({
      account: account,
      scopes: scopes
   });

   return response.accessToken;
};

export const validateAndAcquireIfRequired = async (scopes) => {
   const account = msalInstance.getActiveAccount();

   if (!account) {
      throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
   }

   const response = await msalInstance.acquireTokenSilent({
      account: account,
      scopes: scopes,
      forceRefresh: false
   }).catch(error => {
      if (error instanceof InteractionRequiredAuthError) {
         console.log('InteractionRequiredAuthError, try msalInstance.acquireTokenRedirect', error);
         // fallback to interaction when silent call fails
         return msalInstance.acquireTokenRedirect({
            scopes: scopes,
            loginHint: account.username
         })
      }
   })

   //return response.accessToken;
};

export const getTenantURL = () => {
   const tenantUrl = window.location.href.toLowerCase();
   console.log("getTenantURL - tenantUrl:", tenantUrl)
   if (tenantUrl.includes("engen")) { return 'engen' }
   else if (tenantUrl.includes("hcok")) { return 'hcok' }
   else if (tenantUrl.includes("xohealth")) { return 'xohealth' }
   else if (tenantUrl.includes("lumeris")) { return 'lumeris' }
   else if (tenantUrl.includes("standard") && tenantUrl.includes("sandbox")) { return 'sandbox' }
   else if (tenantUrl.includes("standard") && tenantUrl.includes("demo")) { return 'standard' }
   else { return 'standard' }
};

export const getUserEmailId = () => msalInstance.getActiveAccount().username;
