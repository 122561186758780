import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Snackbar from '@mui/material/Snackbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignIn } from '@fortawesome/free-solid-svg-icons';
import { loginRequest } from "./../../auth/azure-ad-b2c";
import { Alert, Slide } from "@mui/material";

export const SignInButton = () => {
   const { instance } = useMsal();
   const [openSnackbar, setOpenSnackbar] = useState({ open: false, transition: null });
   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);
   const [count, setCount] = useState(5);

   function slideTransition(props) {
      return <Slide {...props} direction="up" />
   }

   useEffect(() => {
      const countTimeout = setTimeout(() => {
         setCount(count - 1)
      }, 1000);

      if (count == 0) {
         clearTimeout(countTimeout);
         handleLogin('redirect');
         handleClose();
      }

      return () => clearTimeout(countTimeout);
   }, [count])

   useEffect(() => {
      setOpenSnackbar({ open: true, transition: slideTransition })
   }, [])


   const handleLogin = (loginType) => {
      setAnchorEl(null);

      if (loginType === "popup") {
         instance.loginPopup(loginRequest);
      } else if (loginType === "redirect") {
         instance.loginRedirect(loginRequest);
      }
   }

   const handleClose = (event, reason) => {
      if (reason === 'clickaway') return;
      setOpenSnackbar({ ...openSnackbar, open: false });
   };

   return (
      <>
         <Snackbar open={openSnackbar.open} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} TransitionComponent={openSnackbar.transition} onClose={handleClose}>
            <Alert variant="filled" severity="success">
               {`Redirecting to the login page in ${count} seconds.`}
            </Alert>
         </Snackbar>

         <Button
            //onClick={(event) => setAnchorEl(event.currentTarget)}
            onClick={() => handleLogin('redirect')}
            color="inherit"
            startIcon={<FontAwesomeIcon icon={faSignIn} />}
         >
            Login
         </Button>
         <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
               vertical: 'top',
               horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
               vertical: 'top',
               horizontal: 'right',
            }}
            open={open}
            onClose={() => setAnchorEl(null)}
         >
            <MenuItem onClick={() => handleLogin("redirect")} key="loginRedirect">Sign-In</MenuItem>
         </Menu>
      </>
   )
};