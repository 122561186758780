import { Button, Dialog, Grid, Typography, Box, Input } from '@mui/material';
import React, { useState } from 'react';
import { DialogContentCustom, DialogTitleCustom } from './DialogCustomModule';


export const ConfirmationDialogBox = ({ open, dialogRequest, handleConfirm, handleCancel }) => {
   const [disableConfirm, setDisableConfirm] = useState(true);
   const [rejectReason, setRejectReason] = useState('');


   const inputChange = (event) => {
      let rejectInput = event.target.value;
      if (!rejectInput) {
         setDisableConfirm(true)
      }
      else {
         setDisableConfirm(false)
         setRejectReason(rejectInput)
      }
   }

   return (
      <Dialog
         open={open}
         disableEscapeKeyDown={true}
         PaperProps={{ sx: { borderRadius: "1rem", width: "30rem" } }}
         className='dialog-common'
      >
         <DialogTitleCustom
            title={dialogRequest.title}
            type={dialogRequest.type}
            onClose={handleCancel}
         />
         <DialogContentCustom>
            <Grid container direction={"column"} alignContent={"center"}>
               <Grid item container direction={"column"}>

                  {
                     dialogRequest.content_title &&
                     <Grid item className="inner-title">
                        <Typography variant="h5">
                           {dialogRequest.content_title}
                        </Typography>
                     </Grid>
                  }

                  {
                     dialogRequest.content &&
                     <Grid item className="body-content">
                        <Typography variant="body2">
                           {dialogRequest.content}
                        </Typography>
                     </Grid>
                  }


                  {
                     dialogRequest.type === "rejectAgreement" ?
                        <Box>
                           <Box className="input-cust-wrapper" width={'100%'} py={1.2}>
                              <Input onChange={(e) => inputChange(e)} placeholder="Enter reason for rejection" fullWidth />
                           </Box>
                           <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} mt={1}>
                              <Button onClick={handleCancel} variant="outlined" color="error" sx={{ borderRadius: '20px', mr: 1 }}>Cancel</Button>
                              <Button onClick={() => handleConfirm(rejectReason)} disabled={disableConfirm} variant="outlined" color="success" sx={{ borderRadius: '20px' }}>Confirm</Button>
                           </Box>
                        </Box> : null
                  }
               </Grid>

            </Grid>
         </DialogContentCustom>
      </Dialog>
   );
}
