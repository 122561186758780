import { useContext, useState } from 'react';
import PdfViewerContext from './PdfViewerContext';
import { useMsal } from "@azure/msal-react";

export const usePdfViewerInstance = () => {
   const activeAccount = useMsal().instance.getActiveAccount();
   const { instance, annotManager } = useContext(PdfViewerContext);
   const [annotPosition, setAnnotPosition] = useState(0);

   const nextAnnotationField = () => {
      let annots = annotManager.getAnnotationsList();
      annots = annots.filter(a => a.fieldName?.startsWith(activeAccount.username.toLowerCase()) || !a.fieldName?.includes("@")).filter(a => a.Subject === "Widget");
      if (annots[annotPosition]) {
         annotManager.jumpToAnnotation(annots[annotPosition]);
         if (annots[annotPosition + 1]) {
            setAnnotPosition(annotPosition + 1);
         }
      }
   }

   const prevAnnotationField = () => {
      let annots = annotManager.getAnnotationsList();
      annots = annots.filter(a => a.fieldName?.startsWith(activeAccount.username.toLowerCase()) || !a.fieldName?.includes("@")).filter(a => a.Subject === "Widget");

      if (annots[annotPosition]) {
         annotManager.jumpToAnnotation(annots[annotPosition]);
         if (annots[annotPosition - 1]) {
            setAnnotPosition(annotPosition - 1);
         }
      }
   }

   const downloadPdf = (includeAnnotations = true, flatten = true) => {
      instance.UI.downloadPdf({
         includeAnnotations: includeAnnotations,
         flatten: flatten,
      });
   }

   const getPdfBlob = async () => {
      const { docViewer, annotManager } = instance;
      const doc = docViewer.getDocument();
      const xfdfString = await annotManager.exportAnnotations({ widgets: true, fields: true });
      const data = await doc.getFileData({ xfdfString });
      const arr = new Uint8Array(data);
      const blob = new Blob([arr], { type: 'application/pdf' });

      return blob;
   }

   const getAllFields = () => {
      const fieldManager = instance.Core.annotationManager.getFieldManager();

      const annots = instance.Core.annotationManager.getAnnotationsList();
      annots.forEach(annot => {
         const field = fieldManager.getField(annot.fieldName);
         console.log(field?.name, field?.value);
      });
   }

   return { instance, annotManager, nextAnnotationField, prevAnnotationField, downloadPdf, getPdfBlob, getAllFields };
};
