import { useAccount } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import Inbox from '../../features/signature/inbox/Inbox';
import { useTheme } from '@mui/material/styles';
import { Container, Grid, useMediaQuery, Box, Typography } from '@mui/material';
import WelcomeName from '../../components/account/WelcomeName';
import { useDispatch, useSelector } from 'react-redux'
import { fetchEnvelopeStatusCount } from '../signature/envelope/envelopeSlice';
import { removeUser } from '../../app/reducer/userSlice';

const Metric = ({ value, subtitle }) => {
   return (
      <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2, color: "#fff" }}>
         <Typography variant="h4">{value}</Typography>
         <Typography variant="subtitle2">{subtitle}</Typography>
      </Box>
   );
};

const Dashboard = () => {
   const theme = useTheme();
   const userAccount = useAccount();
   const dispatch = useDispatch();
   const isLargeViewport = useMediaQuery(theme.breakpoints.up('lg'));
   const envStatusCount = useSelector((state) => state.envelope.envStatusCount)
   const [boxHeight, setBoxHeight] = useState(208);

   useEffect(() => {
      dispatch(fetchEnvelopeStatusCount())
      dispatch(removeUser())
   }, [])

   useEffect(() => {
      setBoxHeight(isLargeViewport ? 208 : 144);
   }, [isLargeViewport]);

   return (
      <Box sx={{ position: 'relative' }}>
         <Box sx={{ height: boxHeight, backgroundColor: theme.palette.primary.light }}></Box>
         <Box sx={{ position: 'absolute', width: '100%', top: boxHeight / (isLargeViewport ? 3 : 5) }}>
            <Container maxWidth="lg">
               <Box pb={1}>
                  <Grid container alignItems="center">
                     <Grid item xs={4}>
                        <Box sx={{ display: "flex", columnGap: 2 }}>
                           <WelcomeName />
                           <Box>
                              <Typography variant="h4" color="#fff">{userAccount.name.replace(/[.]/g, ' ')}</Typography>
                              <Typography variant="caption" color="#fff">Member since: 02/01/2023</Typography>
                           </Box>
                        </Box>
                     </Grid>
                     <Grid item xs={2} sx={{ borderRight: 'solid 1px #fff', paddingLeft: 2 }}>
                        <Metric value={envStatusCount?.data?.actionRequiredCount || 0} subtitle={"Action required"} />
                     </Grid>
                     <Grid item xs={2} sx={{ borderRight: 'solid 1px #fff', paddingLeft: 2 }}>
                        <Metric value={envStatusCount?.data?.awaitingCount || 0} subtitle={"Waiting for others"} />
                     </Grid>
                     <Grid item xs={2} sx={{ borderRight: 'solid 1px #fff', paddingLeft: 2 }}>
                        <Metric value={envStatusCount?.data?.expiredCount || 0} subtitle={"Expired"} />
                     </Grid>
                     <Grid item xs={2} sx={{ paddingLeft: 2 }}>
                        <Metric value={envStatusCount?.data?.completedCount || 0} subtitle={"Completed"} />
                     </Grid>
                  </Grid>
               </Box>
               <Inbox fullVariant={false} />
            </Container>
         </Box>
      </Box>

   );
};
export default Dashboard;
