import { faFileDownload, faFileImport, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogContent, Snackbar, Typography } from "@mui/material";
import { Alert, Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ConfirmationDialogBox } from "../../../components/DialogBox/ConfirmationDialogBox";
import { PageTitle } from "../../../components/layout/PageTitle";
import PdfViewer from "../../../components/pdf-viewer/PdfViewer";
import { usePdfViewerInstance } from "../../../components/pdf-viewer/usePdfViewerInstance";
import ProgressBackdrop from "../../../components/progress-backdrop/ProgressBackdrop";
import { fetchEnvelope, fetchEnvelopeStatusCount, fetchValidate } from "../envelope/envelopeSlice";
import { refreshInbox, selectAll } from "../inbox/inboxSlice";
import { downloadDocument, signDocument, rejectDocument } from "./../../../api/documentApi";
import { validUser } from "../../../app/reducer/userSlice";

const DocumentViewer = ({ document }) => {
   const { instance, downloadPdf, getPdfBlob, getAllFields } = usePdfViewerInstance();
   const { id: envId } = useParams();
   const { docId } = useParams();
   const dispatch = useDispatch();
   const valid = useSelector(state => state.user.valid);
   const [isLoading, setIsLoading] = useState(false);
   const [error, setError] = useState(null);
   const [hasSignedSuccessfully, setHasSignedSuccessfully] = useState(false);
   const [documentBlob, setDocumentBlob] = useState(null);
   const [dialog, setDialog] = useState(false);
   const [open, setOpen] = useState(false);
   const [docSubmitted, setDocSubmitted] = useState(false);
   const envelopes = useSelector(selectAll);
   const documentStatus = envelopes.filter(item => item.id == envId);
   const userSignStatus = ['Signed', "Completed", "Declined", "In Progress"].includes(documentStatus[0]?.status);
   const userDescrption = ['Amendment'].includes(documentStatus[0]?.description);
   const [disableReject, setDisableReject] = useState(true);
   const [signStatus, setSignStatus] = useState(userSignStatus);
   const [disableSubmit, setDisableSubmit] = useState(true);
   const [disableDescrption, setDisableDescrption] = useState(null);
   const [documentLoad, setDocumentLoad] = useState(false);
   const confirmRequest = {
      title: "Reject Agreement",
      content_title: "Confirm to Reject the Agreement?",
      type: "rejectAgreement",
      // content: `Confirm to Reject the Agreement?`,
   };
   const [openDialog, setOpenDialog] = useState(false);

   const tenantUrl = window.location.href.toLowerCase().includes("engen");

   useEffect(() => {
      if (!envId || !docId || documentBlob) return;

      (async () => {
         setIsLoading(true);
         setError(null);

         try {
            const response = await downloadDocument(envId, docId);
            setDocumentBlob(response);
         } catch (err) {
            console.error(err);
            setError("An error occurred while downloading the requested document, please try again later.");
         }

         setIsLoading(false);
      })();
   }, [envId, docId, documentLoad]);

   useEffect(() => {
      if (!instance || !documentBlob) return;

      getAllFields();

      (async () => {
         try {
            //FileSaver.saveAs(response, "sample.pdf");
            const { documentViewer } = instance.Core;
            const { Core, UI } = instance;

            UI.openElements(['loadingModal']);
            // Register all event listeners
            documentViewer.addEventListener('documentLoaded', () => {
               // Hide loader
               UI.closeElements(['loadingModal']);
            });

            const blob = new Blob([documentBlob], { type: "application/pdf" });
            const doc = await Core.createDocument(blob, {
               extension: "pdf",
               l: 'The Most Group Inc  dba Simplify Healthcare Technology:OEM:CollaborateTM::B+:AMS(20251019):B3A5A46204C7380A8360B03AC9A2527860611F859F78FD92DD9F7BFACF525037128A31F5C7',
            });
            // TODO: Add WebViewer license here.
            UI.loadDocument(doc, { fileName: "document.pdf" });
            // TODO: Put proper file name from response headers.

         } catch (err) {
            console.error(err);
            if (!instance || !documentBlob) {
               //need to change the filename get from api (in response header)
               setError("An error occurred while downloading the requested document, please try again later.");
            }
         }
         setSignStatus(userSignStatus)
      })();
   }, [instance, documentBlob, documentLoad]);

   const submitSignedDocument = async () => {
      setIsLoading(true);

      try {
         var blob = await getPdfBlob();
         await signDocument(envId, docId, blob);
         setHasSignedSuccessfully(true);
         setOpen(true);
         await dispatch(refreshInbox());
         await dispatch(fetchEnvelopeStatusCount())
         await dispatch(fetchEnvelope(envId))
      } catch (err) {
         setError("An error occurred while trying to save document, please try again later.");
      }
      setDocumentBlob(null);
      setDocumentLoad(true);
      setDocSubmitted(true);
      setIsLoading(false);
      setDialog(false);
   };

   useEffect(() => {
      setDisableDescrption(userDescrption)
   }, [userDescrption])

   useEffect(() => {
      try {
         dispatch(refreshInbox());
         dispatch(fetchValidate(envId))
      } catch (err) {
         setError(err.message);
      }
      setSignStatus(userSignStatus);
      dispatch(validUser());
      return () => false
   }, [])

   useEffect(() => {
      signStatus && setDocSubmitted(true);
      docSubmitted && setSignStatus(userSignStatus);
      !userSignStatus && setDisableReject(false);
      return () => false
   }, [signStatus, docSubmitted, envelopes])

   const handleClose = () => setDialog(false);
   const handleSnackbarClose = () => setOpen(false);

   const rejectAgreement = async () => {
      setOpenDialog(true)
   }

   const handleConfirmRejct = async (rejectMsg) => {
      try {
         await rejectDocument(envId, docId, rejectMsg);
         setDisableReject(true)
         await dispatch(fetchEnvelopeStatusCount())
         await dispatch(fetchEnvelope(envId))
         setOpenDialog(false)
         dispatch(refreshInbox());
      }
      catch (err) {
         console.log("error:", err)
      }
   }

   const handleRejectClose = () => {
      setOpenDialog(false)
   }

   return (
      <Box>
         <PageTitle title={document?.title} helperText={document?.description} allowBack spacing={1} rightAction={
            <Box sx={{ display: "flex", columnGap: 2 }}>
               <Button color="secondary" variant="outlined" startIcon={<FontAwesomeIcon icon={faFileDownload} />} onClick={() => downloadPdf()}>Download</Button>

               {valid ? null : <>
                  <Button color="primary" variant="contained" startIcon={<FontAwesomeIcon icon={faFileImport} />} disabled={disableSubmit || docSubmitted} onClick={() => setDialog(true)}>Submit</Button>

                  <Button color="primary" variant="contained" startIcon={<FontAwesomeIcon icon={faXmarkCircle} />} disabled={docSubmitted || disableReject || !tenantUrl || disableDescrption} onClick={() => rejectAgreement()}>Reject</Button>
               </>}
            </Box>
         }
         />

         {error && <Alert severity="error" mt={2}>{error}</Alert>}

         <PdfViewer setDisableSubmit={setDisableSubmit} />

         {dialog ? null : <ProgressBackdrop open={isLoading} />}

         <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={handleSnackbarClose}>
            <Alert variant="filled" severity="success" onClose={handleSnackbarClose}>Signature saved successfully.</Alert>
         </Snackbar>
         <Dialog open={dialog} sx={{ ".MuiDialog-container > div": { width: 320, padding: "20px" } }}>
            <DialogContent style={{ fontSize: "16px", fontFamily: "Montserrat", fontWeight: 400 }} >Do you want to Submit ?{" "}</DialogContent>
            <DialogActions>
               <Button variant="outlined" color="error" onClick={handleClose}>Cancel</Button>

               <LoadingButton variant="contained" color="primary" onClick={submitSignedDocument} loading={isLoading}>
                  <Typography> Submit</Typography>
               </LoadingButton>
            </DialogActions>
         </Dialog>

         <ConfirmationDialogBox open={openDialog} dialogRequest={confirmRequest} handleConfirm={handleConfirmRejct} handleCancel={handleRejectClose} />
      </Box>
   );
};
export default DocumentViewer;
