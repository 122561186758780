import { createSlice } from "@reduxjs/toolkit";
import { getUserEmailId } from "../../api/apiToken";

const initialState = {
   id: '',
   valid: false
}

const userSlice = createSlice({
   name: 'user',
   initialState,
   reducers: {
      addUser: (state, action) => {
         state.id = action.payload;
      },
      validUser: (state) => {
         const id = getUserEmailId();
         state.valid = state.id == id;
      },
      removeUser: (state) => {
         state.id = '';
         state.valid = false;
      }
   },
})

export const { addUser, removeUser, validUser } = userSlice.actions

export default userSlice.reducer
